export const Grid = {
    visible: false,

    init: () => {
        window.addEventListener('keydown', Grid.toggle);
        Grid.target = document.querySelector('#test-grid');
        if (Grid.visible) Grid.show();
    },
    
    toggle: (e) => {
        if (e.keyCode !== 71) return;
        Grid.visible ? Grid.hide() : Grid.show(); 
    },
    
    show: () => {
        Grid.visible = true;
        Grid.target.classList.add('view-padding');
    },

    hide: () => {
        Grid.visible = false;
        Grid.target.classList.remove('view-padding');
    },
}