import {throttle} from "../helpers/debounce_throttle";

export const Header_Nav = {
    container: undefined,
    visible: true,
    lastScrollTop: 0,

    init: () => {
        Header_Nav.container = document.getElementById('nav-header');
        Header_Nav.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        window.addEventListener('scroll', throttle( Header_Nav.handleNativeScroll, 100) );
    },

    handleScroll: (direction) => {
        switch(direction) {
            case "down":
                Header_Nav.handleScrollDown();
                break;

            case "up":
                Header_Nav.handleScrollUp();
                break;
        }
    },

    handleScrollDown: () => {
        if (!Header_Nav.visible) return;
        Header_Nav.visible = false;
        Header_Nav.container.classList.add('hide');
    },

    handleScrollUp: () => {
        if (Header_Nav.visible) return;
        Header_Nav.visible = true;
        Header_Nav.container.classList.remove('hide');
    },

    handleNativeScroll: () => {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > Header_Nav.lastScrollTop) {
            Header_Nav.handleScrollDown();
        } else if (st < Header_Nav.lastScrollTop) {
            Header_Nav.handleScrollUp();
        }
        Header_Nav.lastScrollTop = st <= 0 ? 0 : st;
    }
}