export const BrandsInfoCardToggle = {
    containers: undefined,
    triggers: [],

    init: () => {
        BrandsInfoCardToggle.containers = document.getElementsByClassName('brand-container');
        
        for (let i = 0; i < BrandsInfoCardToggle.containers.length; i++) {
            const el = BrandsInfoCardToggle.containers[i];
            const _triggers = el.getElementsByClassName('info-card-trigger');
            
            for (let j = 0; j < _triggers.length; j++) {
                const trig = _triggers[j];
                BrandsInfoCardToggle.triggers.push(trig);
            }
        }

        BrandsInfoCardToggle.addListeners();
    },

    addListeners: () => {
        const _this = this;

        for (let i = 0; i < BrandsInfoCardToggle.triggers.length; i++) {
            const el = BrandsInfoCardToggle.triggers[i];
            el.addEventListener('click', BrandsInfoCardToggle.toggleClickHandler, false);
        }
    },

    toggleClickHandler: (e) => {
        const _showCard = e.target.getAttribute('data-show');
        const _cardContainer = e.target.parentNode.parentNode.parentNode.parentNode;

        if(_showCard == 'false') {
            _cardContainer.classList.remove('info-card--read-more');
        } else {
            _cardContainer.classList.add('info-card--read-more');
        }
    }
}