import {throttle, debounce} from "../helpers/debounce_throttle";

export function InfiniteSlider(args) {
	if(!args) args = {};

	// Slider initial values
	this.slidingDuration = args.slidingDuration || 700;
	this.slidingScrollDelay = args.slidingScrollDelay || 500;
	this.resizeDebounce = args.resizeDebounce || 500;
	this.currentActiveSlide = 0;

	// Methods
	this.init = function() {
		this.setup();
		this.addListeners();
		this.goTo(0);
	}

	this.setup = function() {
		if (args.id) {this.container = document.getElementById(args.id);}
		else {
			let _index = args.index || 0;
			this.container = document.getElementsByClassName('infinite-slider-container')[_index];
		}

		this.slidingWrapper = this.container.getElementsByClassName('sliding-obj')[0];
		this.slides = this.container.getElementsByClassName('slide-container');
		this.totalSlides = this.slides.length;
		this.initClones();

		// Controller buttons
        this.controllers = this.container.getElementsByClassName('slider-controller');
        
        // Tabs (miniatures)
		this.tabs = this.container.getElementsByClassName('slider-tab');
	}

	this.initClones = function() {
		var _this = this;

		var clonesToAppend = [this.slides[0]];
		// console.log('clonesToAppend', clonesToAppend);
		for (let i = 0; i < clonesToAppend.length; i++) {
			let el = clonesToAppend[i];
			let _clone = el.cloneNode(true);
			_this.slidingWrapper.append(_clone);
		}

		var clonesToPrepend = [ this.slides[(this.totalSlides - 1)] ];
		// console.log('clonesToPrepend', clonesToPrepend);
		for (let i = 0; i < clonesToPrepend.length; i++) {
			let el = clonesToPrepend[i];
			let _clone = el.cloneNode(true);
			_this.slidingWrapper.prepend(_clone);
		}
	}

	this.prev = function(){
		var index = this.currentActiveSlide - 1;
		// console.log('index', index);

		if(index < 0){
			this.jumpPositionsTo(this.totalSlides);
			this.goTo(this.totalSlides - 1, true);
		} else {
			this.goTo(index);
		}
	}

	this.next = function(){
		var index = this.currentActiveSlide + 1;
		// console.log('index', index);

		if(index >= this.totalSlides){
			this.jumpPositionsTo(-1);
			this.goTo(0, true);
		} else {
			this.goTo(index);
		}
	}

	this.goTo = function(index, animate){
		// console.log("this.goTo:", index);
		var slideToActivate = index;
		var slidesDisplace = this.getDisplacement() + (this.slideWidth * index);

		if(animate) this.slidingWrapper.classList.add('smooth-sliding');

		this.slidingWrapper.style.transform = 'translate3D(-' + slidesDisplace + 'px, 0, 0)';
		this.setStates(slideToActivate);
	}

	this.jumpPositionsTo = function(index){
		this.slidingWrapper.classList.remove('smooth-sliding');
		this.goTo(index);
	}

	this.getDisplacement = function(){
		this.getSlideWidth();
		var lateralDisplacement = this.slideWidth;
		return lateralDisplacement;
	}

	this.getSlideWidth = function(){
		this.slideWidth = this.container.getElementsByClassName('slide-container')[0].offsetWidth;
	}

	this.setStates = function(slideToActivate, slideToDeactivate){
		// this.updateSlidesState(slideToActivate);
		this.updateTabsState(slideToActivate);
		this.currentActiveSlide = this.normalizeIndex(slideToActivate);
    }
    
    this.updateTabsState = function(slideToActivate) {
		 if (!this.tabs) return

		 var _this = this;
		 _this.tabs[ _this.currentActiveSlide ].classList.remove('active');
		 _this.tabs[ _this.normalizeIndex(slideToActivate) ].classList.add('active');
    }

    this.normalizeIndex = function(_index) {
        var _this = this;

        if(_index < 0) {return 0;}
        if(_index >= _this.totalSlides) { return _this.totalSlides - 1;}
        return _index;
    }


	// Event Handlers
	this.addListeners = function() {
		var _this = this;

		// Swipe actions
		this.container.addEventListener('touchstart', _this.handleTouchStart.bind(_this), false);
		this.container.addEventListener('touchmove', _this.handleTouchMove.bind(_this), false);

		// Scroll actions
		// this.container.addEventListener('wheel', throttle(_this.handleScroll.bind(_this), _this.slidingDuration + _this.slidingScrollDelay));

		// Click actions
		for (let i = 0; i < _this.controllers.length; i++) {
			const el = _this.controllers[i];
			el.addEventListener('click', throttle(_this.handleControllerClick.bind(_this), _this.slidingDuration), false);
		}

		// Resize
		window.addEventListener('resize', debounce(_this.handleResize.bind(_this), _this.resizeDebounce), false);
	}

	this.getTouches = function (evt) {
		return evt.touches;
	}

	this.handleTouchStart = function (evt) {
		// console.log("this.handleTouchStart");
		var firstTouch = this.getTouches(evt)[0];                                      
		this.xDown = firstTouch.clientX;                                      
		this.yDown = firstTouch.clientY;                                      
	};

	this.handleTouchMove = function (evt) {
		var _this = this;

		if ( ! this.xDown || ! this.yDown ) {
			return;
		}

		var xUp = evt.touches[0].clientX;                                    
		var yUp = evt.touches[0].clientY;

		var xDiff = this.xDown - xUp;
		var yDiff = this.yDown - yUp;

		if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant between vertical and horizontal*/
			if ( xDiff > 0 ) {
				/* left swipe */ 
				// console.log("left swipe") ;
				_this.next();
			} else {
				/* right swipe */
				// console.log("right swipe");
				_this.prev();
			}                       
		}
		/* reset values */
		this.xDown = null;
		this.yDown = null;                                             
	};

	this.handleScroll = function(evt) {
		// console.log(evt.deltaX);

		var delta = {
			x: evt.deltaX
		};

		if (delta.x > 0) {
			//user scrolled left
			this.next();
		} else if (delta.x < 0) {
			//user scrolled right
			this.prev();
		}
	}

	this.handleControllerClick = function(evt) {
		evt.preventDefault();
		var _this = this;

		//console.log(evt.target);
		var _slideTo = evt.target.getAttribute('data-slide');
		//console.log("_slideTo", _slideTo);
		switch(_slideTo) {
			case 'prev': _this.prev();
				break;
			case 'next': _this.next();
				break;
			default:
				_this.goTo( parseInt(_slideTo) );
		}
	}

	this.handleResize = function() {
		// console.log("handleResize");
		var _this = this;

		_this.getSlideWidth();
		_this.goTo(_this.currentActiveSlide);
	}
}