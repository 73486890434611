export const Home_Brands_GIF = {
    els : [],
    current: 0,
    intervalTime: 3000,

    init: function() {
        if (!document.getElementsByClassName("gif-wrap").length) return;
        Home_Brands_GIF.getDOMels();
        Home_Brands_GIF.els[0].classList.add("active");
        setTimeout(Home_Brands_GIF.initInterval, 5000);
    },

    getDOMels: function() {
        Home_Brands_GIF.els = document.getElementsByClassName("gif-wrap");
    },

    initInterval: function() {
        setInterval(Home_Brands_GIF.switch, Home_Brands_GIF.intervalTime);
    },

    switch: function() {
        Home_Brands_GIF.els[Home_Brands_GIF.current].classList.remove("active");
        Home_Brands_GIF.els[Home_Brands_GIF.current].classList.add("past");
        
        Home_Brands_GIF.current++;
        if (Home_Brands_GIF.current >= Home_Brands_GIF.els.length) Home_Brands_GIF.current = 0;
        Home_Brands_GIF.els[Home_Brands_GIF.current].classList.remove("past");
        
        setTimeout(_ => {
            Home_Brands_GIF.els[Home_Brands_GIF.current].classList.add("active");
        }, 50);
    }
}