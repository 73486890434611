import {throttle} from "./debounce_throttle";

export const Utils = {
    PI2: 0,
    maxWidth: 0,	
    maxHeight: 0,
    halfWidth: 0,
    halfHeight: 0,
    mouse: {
        getCenterNormal: function(screenW = window.innerWidth, screenH = window.innerHeight) {
            let _center = {
                x: screenW/2,
                y: screenH/2
            }
            let _dx = _center.x - this.x;
            let _dy = _center.y - this.y;
    
            let _normal = {
                x: Math.abs(_dx / _center.x),
                y: Math.abs(_dy / _center.y)
            }
    
            this.centerNormal =_normal;
            return this.centerNormal;
        }
    },

    init: function() {
        this.PI2 = Math.PI*2;

        this.onWindowResize();

        this.mouse.x = this.halfWidth;
        this.mouse.y = this.halfHeight;
        this.mouse.centerNormal = {x: 1, y: 1};

        this.addListeners();
    },

    addListeners: function() {
        var _this = this;

        window.addEventListener("resize", throttle(_this.onWindowResize), false);
    
        window.addEventListener("mousemove", function(event) {
            _this.mouse.x = event.clientX;
            _this.mouse.y = event.clientY;
            _this.mouse.getCenterNormal(_this.maxWidth, _this.maxHeight);
        });
    },

    onWindowResize: function() {
        Utils.maxWidth = window.innerWidth;	
        Utils.maxHeight = window.innerHeight;
        Utils.halfWidth = Utils.maxWidth /2;
        Utils.halfHeight = Utils.maxHeight / 2;
    }
}