import {
    gsap,
    Power2
} from "gsap"

export default class Preloader {
    constructor() {
        this.logo = document.querySelector("#cd-short")
        this.href = ""
        this.setTL()
        this.events()
    }

    events() {
        const links = document.querySelectorAll(".change-page")
        links.forEach((item) => {
            item.addEventListener("click", (e) => this.onClick(e))
        });
    }

    onClick(e) {
        e.preventDefault()
        this.href = e.target
        this.show()
    }

    setTL() {
        var _this = this;
        this.tl = gsap.timeline({
            paused: true,
            delay: 1,
            onReverseComplete: () => {
                window.location = this.href
            },
            onComplete: _this.tlOnComplete
        })
        this.tl.to("#preloader", .35, {
            ease: Power2.easeOut,
            opacity: 0,
            scaleX: 2,
            scaleY: 2,
            display: "none"
        }, .8).to(this.logo, .65, {
            ease: Power2.easeOut,
            opacity: 1,
            stroke: "#fff",
            fill: "#000",
            onStart: () => {
                this.logo.classList.remove("loading")
            }
        }, 0.3).to("#logo-header", 0.45, {
            ease: Power2.easeOut,
            opacity: 1,
            scaleX: 1,
            scaleY: 1
        }, .9)
    }

    hide() {
        this.tl.play()
    }

    show() {
        this.logo.classList.add("loading")
        this.tl.reverse()
    }

    tlOnComplete() {
        document.querySelector('.services-list')?.classList.add('loaded');
        document.querySelector('#brands-title')?.classList.add('loaded');
        setTimeout(_ => { document.querySelector('#brands-grid')?.classList.add('loaded'); }, 600 );
    }
}