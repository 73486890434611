export const Click_Copy = {
    triggers: [],

    init: () => {
        if ( !document.querySelector('a.click-copy-trigger') ) return;
        Click_Copy.getDOMElements();
        Click_Copy.addListeners();
    },

    getDOMElements: () => {
        Click_Copy.triggers = document.querySelectorAll('a.click-copy-trigger')
    },
    
    addListeners: () => {
        Click_Copy.triggers.forEach( link => {
            link.addEventListener('click', (e) => {
                e.preventDefault()
                Click_Copy.clipboardCopy(link)
                link.classList.add('copied')
                setTimeout( () => link.classList.remove('copied'), 2500)
            }, false)
        })
    },

    clipboardCopy: link => {
        let str = link.getAttribute("href").replace("mailto:", "")
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        
    }
}