import {
    gsap,
    Circ,
    Cubic
} from "gsap"

export default class MaskedText {
    constructor(el, onCompleteCallback) {
        this.el = el;
        this.delay = 0.025;
        this.html = this.el.innerHTML;
        this.clean();
        this.setTL(onCompleteCallback);
    }

    show(_delay) {
        gsap.delayedCall(_delay, () => this.tl.play())
    }

    hide() {
        this.tl.reverse();
    }

    setTL(onCompleteCallback) {
        var _this = this;
        this.tl = gsap.timeline({
            paused: true,
            onComplete: onCompleteCallback && _this.tlOnComplete,
            onStart:  _this.tlOnStart
        });
        this.tl.to(this.el, 0.35, {
            ease: Cubic.easeOut,
            opacity: 1,
            y: 0
        }, 0).to(this.el.querySelectorAll("span"), 0.7, {
            ease: Cubic.easeOut,
            clipPath: "inset(0% 0% 0% 0%)",
            stagger: 0.1,
            force3D: true,
            opacity: 1,
            y: 0
        }, 0)
    }

    tlOnStart() {
        if (document.querySelectorAll('.intro-cascaded-item')) {
            setTimeout(() => {
                document.querySelectorAll('.intro-cascaded-item').forEach(item => {
                    item.classList.add('loaded');
                });
            }, 150)
        }
    }

    tlOnComplete() {
        document.querySelector('.gif-container')?.classList.add('loaded');

        if ( document.querySelector('.arrow-btn--cascaded') ) {
            setTimeout(_ => {
                document.querySelector('.arrow-btn--cascaded').classList.add('loaded');
            }, 1500);
        }
    }

    clean() {
        let words = this.html.replace(/\s\s+/g, ' ').split(" ");
        let clean = [];
        let txt = "";
        for (var i = 0; i < words.length; i++) {
            let word = words[i];
            txt += word + " ";
            if (txt.length < 8 && i != words.length - 1) {
                continue;
            }
            clean.push(txt);
            txt = "";
        }
        this.words = clean;
        this.set();
    }

    open(str) {
        if (str.indexOf("<b>") >= 0) return "b";
        else if (str.indexOf("<u>") >= 0) return "u";
        else return false;
    }

    close(str) {
        return (str.indexOf("</b>") >= 0 || str.indexOf("</u>") >= 0); // || str.indexOf(")") >= 0);
    }

    set() {
        let words = [];
        let txt = "";
        let openTag = false;
        for (let i = 0; i < this.words.length; i++) {
            let word = this.words[i];
            if (this.open(word) && this.close(word)) {
                words.push(" " + word);
            } else {
                if (this.open(word)) {
                    this.open_tag = this.open(word);
                    txt = " ";
                    openTag = true;
                }
                if (openTag) {
                    txt += " " + word;
                    if (txt.length > 14 && !this.close(word)) {
                        txt += "</" + this.open_tag + ">";
                        words.push(txt);
                        txt = "<" + this.open_tag + ">";
                    }
                } else words.push(word);
                if (this.close(word)) {
                    words.push(txt + " ");
                    openTag = false;
                    txt = "";
                }
            }
        }
        this.seted = true;
        this.setWords(words);
    }

    setWords(words) {
        let html = "";
        for (var i = 0; i < words.length; i++) html += "<span> " + words[i] + "</span> ";
        this.el.innerHTML = html;
        this.spans = this.el.querySelectorAll("span");
    }

    destroy() {
        this.el.innerHTML = this.html;
        delete this.spans;
        delete this.words;
        delete this.el;
        delete this;
    }

}