import {
    gsap,
    Circ
} from "gsap"

export default class WelcomeText {
    constructor() {
        this.title = document.querySelector("#home-hero-section h1.title")
        this.onResize()
        window.addEventListener("resize", () => this.onResize())
    }

    onResize() {
        let fontSize = Math.max(18, (window.innerWidth * 26) / 1200)
        gsap.set(this.title, {
            fontSize: parseInt(fontSize) + "px"
        })
    }

}