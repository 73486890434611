// Helper functions
export function throttle(fn, minDelay, scope) {
	var lastCall = 0;
	return function() {
		var now = +new Date();
		if (now - lastCall < minDelay) {
			return;
		}
		lastCall = now;
		fn.apply(scope, arguments);
	};
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};