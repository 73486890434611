import {throttle} from "../helpers/debounce_throttle";
import {Utils} from "../helpers/canvas_utils";

export const Slider_Arrows = {
    ready: false,
    canvasID: 'slider-arrows-canvas',
    canvas: undefined,
    ctx: undefined,
    x: 0,
    y: 0,
    showArrow: false,
    renderTriggers: [],

    body: {},
    bodyAnchors: {},
    bodyAnchorsOpened: {},

    leftHead: {},
    leftAnchors: {},
    leftAnchorsOpened: {},

    rightHead: {},
    rightAnchors: {},
    rightAnchorsOpened: {},

    cof: 0.1,
    width: 42,
    height: 30,
    lineWidth: 2,
    defaultColor: "white",
    fade: false,
    colorThreshold: 5,
    alpha: 1,

    init: function() {
        if (!document.getElementById(this.canvasID)) return;
        Utils.init();
        this.initCanvas();
        this.initRenderTriggers();
        this.initMousePosition();
        this.initAnchorsTarget();
        this.addHandlers();
        this.ready = true;
    },

    initCanvas: function() {
        this.canvas = document.getElementById(this.canvasID);
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = this.canvas.getBoundingClientRect().width;
        this.canvas.height = this.canvas.getBoundingClientRect().height;
        this.color = this.defaultColor;
    },

    initRenderTriggers: function() {
        this.renderTriggers = document.querySelectorAll('.canvas-arrow-trigger');
    },

    initMousePosition: function() {
        this.x = Utils.mouse.x;
        this.y = Utils.mouse.y;

        this.body = {
            x1: Utils.mouse.x,
            x2: Utils.mouse.x
        }

        this.leftHead = {
            x1: Utils.mouse.x,
            y1: Utils.mouse.y,

            x2: Utils.mouse.x,
            y2: Utils.mouse.y,

            x3: Utils.mouse.x,
            y3: Utils.mouse.y
        };

        this.rightHead = {
            x1: Utils.mouse.x,
            y1: Utils.mouse.y,
    
            x2: Utils.mouse.x,
            y2: Utils.mouse.y,
    
            x3: Utils.mouse.x,
            y3: Utils.mouse.y
        };
    },

    initAnchorsTarget: function() {
        this.bodyAnchorsOpened = {
            x1: -this.width/2,
            x2: this.width/2
        },

        this.leftAnchorsOpened = {
            x1: 0 - (this.width/2) + (this.height/2),
            y1: 0 - (this.height/2) + (this.lineWidth*.35),
            
            x2: 0 - (this.width / 2) + (this.lineWidth * .2),
            y2: 0,

            x3: 0 - (this.width/2) + (this.height/2),
            y3: 0 + (this.height/2) - (this.lineWidth*.35)
        }

        this.rightAnchorsOpened = {
            x1: 0 + (this.width/2) - (this.height/2),
            y1: 0 - (this.height/2) + (this.lineWidth*.35),
            
            x2: 0 + (this.width / 2) + (this.lineWidth * .2),
            y2: 0,

            x3: 0 + (this.width/2) - (this.height/2),
            y3: 0 + (this.height/2) - (this.lineWidth*.35)
        }

    },
    
    render: function() {
        // if (!this.showArrow) return;
        this.x += (Utils.mouse.x - this.x) * this.cof;
        this.y += (Utils.mouse.y - this.y) * this.cof;
        this.updateAnchors();
        this.lerpVertices();
        this.updateFade();

        this.draw();
    },

    updateAnchors: function() {
        if (this.showArrow) {
            this.bodyAnchors = {
                x1: Utils.mouse.x - this.width/2,
                x2: Utils.mouse.x + this.width/2
            };

            if (Utils.mouse.x < Utils.halfWidth) {
                this.rightAnchors = {
                    x1: Utils.mouse.x - (this.width/2),
                    y1: Utils.mouse.y,
                    
                    x2: Utils.mouse.x - (this.width/2),
                    y2: Utils.mouse.y,

                    x3: Utils.mouse.x - (this.width/2),
                    y3: Utils.mouse.y
                }
                
                this.leftAnchors = {
                    x1: Utils.mouse.x + this.leftAnchorsOpened.x1,
                    y1: Utils.mouse.y + this.leftAnchorsOpened.y1,
                    
                    x2: Utils.mouse.x + this.leftAnchorsOpened.x2,
                    y2: Utils.mouse.y + this.leftAnchorsOpened.y2,

                    x3: Utils.mouse.x + this.leftAnchorsOpened.x3,
                    y3: Utils.mouse.y + this.leftAnchorsOpened.y3
                }

            } else {
                this.rightAnchors = {
                    x1: Utils.mouse.x + this.rightAnchorsOpened.x1,
                    y1: Utils.mouse.y + this.rightAnchorsOpened.y1,
                    
                    x2: Utils.mouse.x + this.rightAnchorsOpened.x2,
                    y2: Utils.mouse.y + this.rightAnchorsOpened.y2,

                    x3: Utils.mouse.x + this.rightAnchorsOpened.x3,
                    y3: Utils.mouse.y + this.rightAnchorsOpened.y3
                }
                
                this.leftAnchors = {
                    x1: Utils.mouse.x + (this.width/2),
                    y1: Utils.mouse.y,
                    
                    x2: Utils.mouse.x + (this.width/2),
                    y2: Utils.mouse.y,

                    x3: Utils.mouse.x + (this.width/2),
                    y3: Utils.mouse.y
                }
            }
        } else {
            let _exitAnchors = {
                x: Utils.mouse.x,
                y: Utils.mouse.y
            };
            if (Utils.mouse.x > Utils.halfWidth) {
                _exitAnchors.x += this.width/2;
            } else {
                _exitAnchors.x -= this.width/2;
            }
            
            this.bodyAnchors = {
                x1: _exitAnchors.x,
                x2: _exitAnchors.x
            },

            this.rightAnchors = {
                x1: _exitAnchors.x,
                y1: _exitAnchors.y,
                
                x2: _exitAnchors.x,
                y2: _exitAnchors.y,

                x3: _exitAnchors.x,
                y3: _exitAnchors.y
            }
            
            this.leftAnchors = {
                x1: _exitAnchors.x,
                y1: _exitAnchors.y,
                
                x2: _exitAnchors.x,
                y2: _exitAnchors.y,

                x3: _exitAnchors.x,
                y3: _exitAnchors.y
            }
        }
    },

    lerpVertices: function() {
        this.body.x1 += (this.bodyAnchors.x1 - this.body.x1) * this.cof;
        this.body.x2 += (this.bodyAnchors.x2 - this.body.x2) * this.cof;
        
        this.leftHead.x1 += (this.leftAnchors.x1 - this.leftHead.x1) * this.cof;
        this.leftHead.y1 += (this.leftAnchors.y1 - this.leftHead.y1) * this.cof;

        this.leftHead.x2 += (this.leftAnchors.x2 - this.leftHead.x2) * this.cof;
        this.leftHead.y2 += (this.leftAnchors.y2 - this.leftHead.y2) * this.cof;

        this.leftHead.x3 += (this.leftAnchors.x3 - this.leftHead.x3) * this.cof;
        this.leftHead.y3 += (this.leftAnchors.y3 - this.leftHead.y3) * this.cof;

        this.rightHead.x1 += (this.rightAnchors.x1 - this.rightHead.x1) * this.cof;
        this.rightHead.y1 += (this.rightAnchors.y1 - this.rightHead.y1) * this.cof;

        this.rightHead.x2 += (this.rightAnchors.x2 - this.rightHead.x2) * this.cof;
        this.rightHead.y2 += (this.rightAnchors.y2 - this.rightHead.y2) * this.cof;

        this.rightHead.x3 += (this.rightAnchors.x3 - this.rightHead.x3) * this.cof;
        this.rightHead.y3 += (this.rightAnchors.y3 - this.rightHead.y3) * this.cof;
    },

    updateFade: function() {
        if (!this.showArrow) {
            let dist = Math.abs(this.body.x1 - this.body.x2)
            if (dist < this.colorThreshold && !Slider_Arrows.fade) {
                Slider_Arrows.canvas.classList.add('fade');
                Slider_Arrows.fade = true;
            }
        }
    },

    draw: function() {
        this.ctx.clearRect(0, 0, Utils.maxWidth, Utils.maxHeight);
        // this.drawBox();
        this.drawBody();
        this.drawHeads();
    },

    drawBox: function() {
        this.ctx.beginPath();
        this.ctx.globalAlpha= this.alpha;
        this.ctx.lineWidth= 1;
        this.ctx.rect( (this.x - this.width/2), (this.y - this.height/2), this.width, this.height);
        this.ctx.strokeStyle = this.color;
        this.ctx.stroke();
        this.ctx.closePath();
    },
    
    drawBody: function() {
        this.ctx.beginPath();
        this.ctx.moveTo(this.body.x1, this.y);
        this.ctx.lineTo(this.body.x2, this.y);
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.strokeStyle = this.color;
        this.ctx.stroke();
        this.ctx.closePath();
    },
    
    drawHeads: function() {
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.strokeStyle = this.color;
        
        this.ctx.beginPath();
        this.ctx.moveTo( this.leftHead.x1, this.leftHead.y1 );
        this.ctx.lineTo( this.leftHead.x2, this.leftHead.y2 );
        this.ctx.lineTo( this.leftHead.x3, this.leftHead.y3 );
        this.ctx.stroke();
        this.ctx.closePath();
        
        this.ctx.beginPath();
        this.ctx.moveTo( this.rightHead.x1, this.rightHead.y1 );
        this.ctx.lineTo( this.rightHead.x2, this.rightHead.y2 );
        this.ctx.lineTo( this.rightHead.x3, this.rightHead.y3 );
        this.ctx.stroke();
        this.ctx.closePath();
    },

    addHandlers: function() {
        const _this = this;
        window.addEventListener("resize", throttle(Slider_Arrows.onWindowResize), false);
        this.renderTriggers.forEach(trigger => {
            trigger.addEventListener('mouseenter', _this.onTriggerOver);
            trigger.addEventListener('mouseleave', _this.onTriggerOut);
        });
    },

    onWindowResize: function() {
        Slider_Arrows.canvas.width = Slider_Arrows.canvas.getBoundingClientRect().width;
        Slider_Arrows.canvas.height = Slider_Arrows.canvas.getBoundingClientRect().height;
    },

    onTriggerOver: function() {
        Slider_Arrows.showArrow = true;
        Slider_Arrows.canvas.classList.remove('fade');
    },
    onTriggerOut: function() {
        Slider_Arrows.showArrow = false;
        Slider_Arrows.fade = false;
    }
}