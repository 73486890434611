import {Utils} from "./canvas_utils";

export const Dot = function(args) {
	if (!args) args = {};

    this.ctx = args.context || context;
    this.frame = 0;
    this.amplitude = 10;

    this.x = Utils.halfWidth;
    this.y = 200;
    // this.y = Utils.halfHeight;
    this.anchor = {
        x: Utils.halfWidth,
        y: 200,
        // y: Utils.halfHeight,
        angle: args.angle
    }
    this.groupRadiusMin = args.groupRadiusMin || 100;
    this.groupRadiusMax = args.groupRadiusMax || 400;
    this.groupRadius = 0;
    this.groupRotationSpeed = args.groupRotationSpeed || 0.005;
    this.rotationSpeed = 0.05;
    this.radians = args.radians || 0.01;
    this.rotationRadius = 10;
    this.cof = 0.1;
	this.radius = 6;
	this.fillColor = '#b5b5b5';
	this.alpha = 1;

    this.update = function() {
        this.updateRadius();
        this.updateAnchor();
        this.updateRotation();
		// this.x += (this.anchor.x - this.x) * this.cof;
		// this.y += (this.anchor.y - this.y) * this.cof;
		this.draw();
	}

    this.updateRadius = function() {
        let _targetRadius = Utils.mouse.centerNormal.x * (this.groupRadiusMax - this.groupRadiusMin) / 1 + this.groupRadiusMin;  
        this.groupRadius += (_targetRadius - this.groupRadius) * this.cof;
    }

    this.updateAnchor = function() {
        // this.frame += 0.1;

        this.anchor.angle += this.groupRotationSpeed;
        this.anchor.x = Utils.halfWidth + (Math.cos(this.anchor.angle) * this.groupRadius);
        // this.anchor.y = Utils.halfHeight + (Math.sin(this.anchor.angle) * this.groupRadius);
        this.anchor.y = 200 + (Math.sin(this.anchor.angle) * this.groupRadius);
    }

    this.updateRotation = function() {
        this.radians += this.rotationSpeed;
        this.x = (this.anchor.x + Math.cos(this.radians) * this.rotationRadius);
		this.y = (this.anchor.y + Math.sin(this.radians) * this.rotationRadius);
    }
	
	this.draw = function() {
        if (!this.ctx) return console.warn('no valid context for drawing');
		this.ctx.beginPath();
		this.ctx.globalAlpha= this.alpha;
		this.ctx.arc(this.x, this.y, this.radius, 0, Utils.PI2, false);
		this.ctx.fillStyle = "#fff";
		this.ctx.fill();
		this.ctx.strokeStyle = this.fillColor;
		this.ctx.lineWidth = 0.75;
		this.ctx.stroke();
		this.ctx.closePath();
	}
	
	this.render = function(){
		this.update();
	}
}