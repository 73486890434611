import {throttle} from "../helpers/debounce_throttle";
import {Utils} from "../helpers/canvas_utils";
import {Dot} from "../helpers/dots-class";

export const Creativity_Canvas = {
    ready: false,
    canvasID: 'creativity-canvas',
    canvas: undefined,
    ctx: undefined,
    
    cof: 0.1,
    width: 70,
    height: 54,
    lineWidth: 4,
    color: "white",

    alpha: 1,
    dots: [],
    dots2: [],
    dots3: [],
    totalDots: 20,
    sketchColor: '#b5b5b5',

    init: function() {
        if (!document.getElementById(this.canvasID)) return;
        Utils.init();
        this.initCanvas();
        this.initDots();
        this.addHandlers();
        this.ready = true;
    },

    initCanvas: function() {
        this.canvas = document.getElementById(this.canvasID);
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = this.canvas.getBoundingClientRect().width;
        this.canvas.height = this.canvas.getBoundingClientRect().height;
    },

    initDots: function() {
        let _angle = Utils.PI2 / Creativity_Canvas.totalDots;
        
        for (let i = 0; i < Creativity_Canvas.totalDots; i++) {
            let _displacementAngle = (_angle * i) - Utils.PI2/4;

            const el = new Dot({
                context: Creativity_Canvas.ctx,
                angle: _displacementAngle
                // radians: Math.random() * Utils.PI2
            });

            Creativity_Canvas.dots.push(el);
        }
        
        for (let i = 0; i < Creativity_Canvas.totalDots; i++) {
            let _displacementAngle = (_angle * i) + Utils.PI2/4;

            const el = new Dot({
                context: Creativity_Canvas.ctx,
                groupRadiusMin: 80,
                groupRadiusMax: 450,
                groupRotationSpeed: -0.01,
                angle: _displacementAngle,
                radians: Math.random() * Utils.PI2
            });

            Creativity_Canvas.dots2.push(el);
        }
        
        for (let i = 0; i < Creativity_Canvas.totalDots; i++) {
            let _displacementAngle = (_angle * i);

            const el = new Dot({
                context: Creativity_Canvas.ctx,
                groupRadiusMin: 120,
                groupRadiusMax: 380,
                groupRotationSpeed: 0.015,
                angle: _displacementAngle,
                radians: Math.random() * Utils.PI2
            });

            Creativity_Canvas.dots3.push(el);
        }
    },
    
    render: function() {
        this.draw();
    },


    draw: function() {
        this.ctx.clearRect(0, 0, Utils.maxWidth, Utils.maxHeight);

        this.connectDots(this.dots, this.ctx);
        this.connectDots(this.dots2, this.ctx);
        this.connectDots(this.dots3, this.ctx);

        this.dots.forEach(dot => { dot.update() });
        this.dots2.forEach(dot => { dot.update() });
        this.dots3.forEach(dot => { dot.update() });
        
    },

    connectDots: function(_dots, _context) {
        _context.beginPath();
        _context.globalAlpha= 1;
        
        let _lastDot = _dots[_dots.length-1];
        _context.moveTo(_lastDot.x, _lastDot.y);
        _dots.forEach(_dot => {
            _context.lineTo(_dot.x, _dot.y);
        });
        
        _context.strokeStyle = Creativity_Canvas.sketchColor;
        _context.lineWidth = 0.75;
        _context.stroke();
        _context.closePath();
    },
    
    addHandlers: function() {
        window.addEventListener("resize", throttle(Creativity_Canvas.onWindowResize), false);
    },

    onWindowResize: function() {
        Creativity_Canvas.canvas.width = Creativity_Canvas.canvas.getBoundingClientRect().width;
        Creativity_Canvas.canvas.height = Creativity_Canvas.canvas.getBoundingClientRect().height;
    }
}