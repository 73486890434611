import {gsap,Power2} from "gsap/all"

export default class Contact {
    constructor() {
        this.container = document.querySelector("#map")
        this.attempts = 0
        if (this.container) this.setMap()
    }

    events() {
        this.links = document.querySelectorAll(".country-selector")
        this.links.forEach(link => this.addCountryEvent(link))
    }

    addMarker(latLng, _country) {
        return new google.maps.Marker({
            position: latLng,
            title: _country
        })
    }

    addCountryEvent(link) {
        if (!link.classList.contains("disabled")) {
            const latLng = { lat: parseFloat(link.dataset.lat), lng: parseFloat(link.dataset.lng) }
            const marker = this.addMarker(latLng, link.getAttribute("title"))
            marker.setMap(this.map)
            link.addEventListener("click", (e) => {
                e.preventDefault()
                
                document.querySelectorAll(".country-selector.active").forEach(_link => _link.classList.remove("active"))
                link.classList.add("active")
                this.map.panTo(latLng)
                
                document.querySelectorAll(".ubication-item.active").forEach(_ubication => _ubication.classList.remove("active"))
                
                const _target = document.querySelector(link.getAttribute("href"))
                _target.classList.add("active")
    
                gsap.set(_target.querySelectorAll('.contact-info'), {
                    y: 30,
                    opacity: 0
                })
                gsap.to(_target.querySelectorAll('.contact-info'), {
                    ease: Power2.easeOut,
                    duration: .45,
                    stagger: .15,
                    y: 0,
                    opacity: 1
                })
            })
        }
    }

    setMap() {
        this.attempts += 1
        if (!google) {
            if (this.attempts < 3) return setTimeout(() => this.setMap(), 500)
            throw("Falló la conexión a google");
        }
        this.map = new google.maps.Map(this.container, {
            center: {
                lat: 19.423786,
                lng: -99.133229
            },
            zoom: 12,
            styles: [{
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#212121"
                    }]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "color": "#212121"
                    }]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#bdbdbd"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#181818"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#616161"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "color": "#1b1b1b"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#2c2c2c"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#8a8a8a"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#373737"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#3c3c3c"
                    }]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#4e4e4e"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#616161"
                    }]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#000000"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#3d3d3d"
                    }]
                }
            ]
        })
        this.events()
    }
}