export const RAF = {
	els: [],
	add: function(object) {
		if(object.init) object.init();
		if(object.ready) this.els.push(object);
	},
	remove: function(object) {
		var idx = this.els.indexOf(object);
		this.els.splice(idx, 1);
	},
	init: function() {
		this.animate();
	},
	animate: function() {
		requestAnimationFrame(RAF.animate);
		RAF.render();
	},
	render: function() {
		if (RAF.els.length <= 0) return;
        for (var i = 0; i < RAF.els.length; i++) {
			RAF.els[i].render();
		}
	}
}