import Rails from "@rails/ujs";
Rails.start();

import LocomotiveScroll from 'locomotive-scroll';
var scroll;

import {
    RAF
} from "./helpers/RAF";
import {
    Grid
} from "./helpers/grid";

import {
    Side_Menu
} from "./global/side-menu";
import Preloader from "./global/preloader";
import {Header_Nav} from "./global/header-nav";

import {
    InfiniteSlider
} from "./modules/infinite-slider";
import {
    BrandsInfoCardToggle
} from "./modules/brands-info-card";
import {
    Slider_Arrows
} from "./modules/slider-arrows";
import {
    Home_Brands_GIF
} from "./modules/home-brands-gif"
import {
    Creativity_Canvas
} from "./modules/creativity-canvas";
import {
    Click_Copy
} from "./modules/click-copy"
import Contact from "./modules/contact";

import WelcomeText from "./modules/welcome-text"
import MaskedText from "./modules/masked-text"

class App {
    constructor() {
        this.preloader = new Preloader();
        this.contact = new Contact()
            // Dev helpers
        Grid.init();

        // Page modules initializers
        Header_Nav.init();
        Side_Menu.init();
        Home_Brands_GIF.init();
        this.initSliders();
        BrandsInfoCardToggle.init();
        Click_Copy.init();

        this.initRAFs();
        this.setTitles();
    }

    initRAFs() {
        if (window.innerWidth < 768) return;
        RAF.init();
        RAF.add(Creativity_Canvas);
        RAF.add(Slider_Arrows)
    }

    setTitles() {
        const title = new WelcomeText()
        // 
        this.titles = [];
        const titles = document.querySelectorAll(".masked-text");
        titles.forEach((item, index) => {
            let onCompleteCallback = index >= titles.length - 1;
            const text = new MaskedText(item, onCompleteCallback);
            this.titles.push(text);
        })
    }

    loaded() {
        this.preloader.hide()
        this.initSmoothScroll();
        this.titles.forEach((text, idx) => text.show((idx * .275) + 2));
    }

    initSliders() {
        const _this = this;

        _this.PageSliders = [];
        _this.DOMSliders = document.getElementsByClassName('infinite-slider-container');

        for (let i = 0; i < _this.DOMSliders.length; i++) {
            const el = new InfiniteSlider({
                index: i
            });
            el.init();
            _this.PageSliders.push(el);
        }
    }

    initSmoothScroll() {
        scroll = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: !MOBILE
        });

        let triggers = document.getElementsByClassName('scroll-to-js');
        let scaleObjs = document.querySelectorAll('[data-scroll-id ^= scale]');
        let scaleStrength = 0.4;

        for (let i = 0; i < triggers.length; i++) {
            triggers[i].addEventListener('click', function(e) {
                e.preventDefault();
                let _triggerTarget = e.target.getAttribute('href')
                let _target = document.querySelector(_triggerTarget);
                scroll.scrollTo(_target);
            });
        }

        scroll.on('scroll', (args) => {
            // console.log("call", args.currentElements)
            for (let i = 0; i < scaleObjs.length; i++) {
                const _key = `scale-${i}`;
                if (typeof args.currentElements[_key] === 'object') {
                    let progress = args.currentElements[_key].progress;
                    let scale = scaleStrength - (progress * scaleStrength) + 1;
                    args.currentElements[_key].el.style.transform = `scale(${scale})`;
                }
            }

            let direction = (args.delta?.y - args.scroll.y > 0) ? "down" : "up";
            if (args.delta) Header_Nav.handleScroll(direction);
        });

        scroll.on('call', (name, type, node) => {
            if (name == 'maskText' && !node.el.className.includes('masked-text') ) {
                node.el.classList.add('masked-text');
                let text = new MaskedText(node.el);
                text.show(.325);
            }
        });
    }
}

let _app = new App();

window.onload = () => _app.loaded();